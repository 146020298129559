<template>
    <NuxtLayout name="default">
        <div class="flex flex-grow layout-account">
            <account-sidebar />

            <div class="flex-1 flex flex-col">
                <div class="p-8 lg:p-20 flex-grow">
                    <slot />
                </div>

                <layout-footer />
            </div>
        </div>
    </NuxtLayout>
</template>
